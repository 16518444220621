import React from "react";
import { useTranslation } from "react-i18next";

import ColorPicker from "../../../../../components/form/ColorPicker";
import HierarchicalSelect from "../../../../../components/form/HierarchicalSelect";
import Select from "../../../../../components/form/Select";
import TextField from "../../../../../components/form/TextField";
import { getFullPartitionName } from "../../../functions/partition";
import { ChartSeries, seriesTypes } from "../../../models/series";
import { SolutionDetail } from "../../../models/solution";
import { tenum } from "../../../services/translationMappings";

interface SeriesFormProps {
  solution: SolutionDetail;
  series: ChartSeries;
  onChange: React.Dispatch<React.SetStateAction<ChartSeries>>;
}

export default function SeriesForm(props: SeriesFormProps): JSX.Element {
  const { solution, series, onChange } = props;
  const { measurements, partitioners, partitions } = solution;

  const partition =
    solution.partitions.find((p) => p.partitionId === series.partitionId) ??
    null;
  const measurement =
    solution.measurements.find(
      (m) => m.measurementId === series.measurementId
    ) ?? null;

  const [dirtyName, setDirtyName] = React.useState(
    !partition ||
      series.name !== getFullPartitionName(solution.partitions, partition)
  );

  const { t } = useTranslation();

  return (
    <div className="w-full child:py-2">
      <HierarchicalSelect
        levels={partitioners}
        options={partitions}
        value={partition}
        onChange={(partition) =>
          partition &&
          onChange((prev) => ({
            ...prev,
            partitionId: partition.partitionId,
            name:
              !dirtyName && partition
                ? getFullPartitionName(partitions, partition)
                : prev.name,
          }))
        }
      />
      <div>
        <Select
          id="seriesFormEditMeasurement"
          label={t("Observable")}
          options={measurements}
          keyProp="measurementId"
          labelProp="name"
          value={measurement}
          onChange={(measurement) =>
            onChange((prev) => ({
              ...prev,
              measurementId: measurement.measurementId,
              unit: measurement.name,
            }))
          }
        />
      </div>
      <TextField
        id="series_name"
        label={t("Label")}
        value={series.name}
        onChange={(e) => {
          setDirtyName(true);
          onChange((prev) => ({
            ...prev,
            name: e.target.value,
          }));
        }}
      />
      <div className="flex align-bottom">
        <div className="inline-block w-1/2 pr-1">
          <Select
            id="seriesFormEditType"
            label={t("Type")}
            options={seriesTypes
              .filter((type) => type !== "Interval")
              .map((key) => ({ key, label: tenum(key, t) }))}
            value={{
              key: series.seriesType,
              label: tenum(series.seriesType, t),
            }}
            onChange={(option) =>
              onChange((prev) => ({
                ...prev,
                seriesType: option.key,
              }))
            }
          />
        </div>
        <div className="inline-block w-1/2 pl-1">
          <ColorPicker
            label={t("Color")}
            value={series.color}
            onChange={(e) =>
              onChange((prev) => ({
                ...prev,
                color: e.target.value,
              }))
            }
          />
        </div>
      </div>
    </div>
  );
}

export const seriesColorPalette = [
  "#0c59cf",
  "#32cd32",
  "#ffa300",
  "#e60049",
  "#9b19f5",
  "#4c7a34",
  "#a8c6fa",
  "#dc0ab4",
  "#00bfa0",
  "#cce90e",
] as const;
