import React from "react";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  Dialog,
  DialogPanel,
  Popover,
  PopoverBackdrop,
  PopoverButton,
  PopoverPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";

import {
  ArrowLeftStartOnRectangleIcon,
  Bars3Icon,
  ChevronLeftIcon,
  ChevronRightIcon,
  Squares2X2Icon,
  UserIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import logo from "../../../public/images/logo.png";
import { SolutionDetail } from "../../apps/PrognosAI/models/solution";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { HeroIcon } from "../../models/primitives";
import LogoutForm from "../../pages/Login/LogoutForm";
import { getSolutionsPath } from "../../routes/solutions";
import { toggleSidebar, useGlobalState } from "../../services/globalState";
import Apps from "../Apps";
import { useAuth } from "../AuthProvider";
import Portal from "../Portal";
import Version from "./Version";

export type CustomIcon = (props: React.SVGProps<SVGSVGElement>) => JSX.Element;

export interface SidebarItem {
  key: string;
  name: string;
  href: string;
  icon: HeroIcon | CustomIcon;
  current: boolean;
  children?: Pick<SidebarItem, "name" | "href" | "current">[];
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface SidebarProps {
  topNavigation: SidebarItem[][];
  bottomNavigation?: SidebarItem[];
  solution?: SolutionDetail;
  children?: React.ReactNode;
  forceCollapsed?: boolean;
}

export default function Sidebar(props: SidebarProps) {
  const { topNavigation, bottomNavigation, solution, children } = props;
  const { forceCollapsed = false } = props;

  // mobile version sidebar toggle
  const [sidebarOpen, setSidebarOpen] = useState(false);
  // desktop version sidebar toggle
  const [state, dispatch] = useGlobalState();
  const {
    sidebar: { collapsed: stateCollapsed },
  } = state;
  const handleSidebarToggle = () => dispatch(toggleSidebar());
  const collapsed = stateCollapsed || forceCollapsed;

  useCurrentPageTitle(topNavigation, bottomNavigation, solution);

  return (
    <div className="h-full">
      <Transition show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <TransitionChild
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </TransitionChild>

          <div className="fixed inset-0 z-40 flex">
            <TransitionChild
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <DialogPanel className="relative flex w-full max-w-xs flex-1 flex-col bg-white">
                <TransitionChild
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <MobileMenuCloseButton
                    onClick={() => setSidebarOpen(false)}
                  />
                </TransitionChild>
                <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                  <Header />
                  <div className="px-2">
                    <Navigation
                      itemGroups={
                        bottomNavigation
                          ? topNavigation.concat([bottomNavigation])
                          : topNavigation
                      }
                    />
                  </div>
                </div>
                <Version />
                <UserProfile />
              </DialogPanel>
            </TransitionChild>
            <div className="w-14 flex-shrink-0">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition>

      {/* Static sidebar for desktop */}
      <NavigationSection
        showLogo={!forceCollapsed}
        collapsed={collapsed}
        topNavigation={topNavigation}
        bottomNavigation={bottomNavigation}
        onVisibilityChange={!forceCollapsed ? handleSidebarToggle : undefined}
      />

      <div
        // experimental: the whole UI inside of this <div> re-renders if the sidebar is toggled
        key={collapsed ? "Collapsed" : "Full"}
        className={classNames(
          "h-full flex flex-1 flex-col",
          !collapsed ? "md:pl-64" : "md:pl-14"
        )}
      >
        <MobileMenuOpenButton onClick={() => setSidebarOpen(true)} />
        {children}
      </div>
    </div>
  );
}

function useCurrentPageTitle(
  topNavigation: SidebarItem[][],
  bottomNavigation?: SidebarItem[],
  solution?: SolutionDetail
) {
  const currentPage = topNavigation
    .flat()
    .concat(bottomNavigation ?? [])
    .find((page) => page.current);

  useDocumentTitle(currentPage?.name, solution);
}

interface NavigationSectionProps {
  showLogo: boolean;
  collapsed: boolean;
  topNavigation: SidebarItem[][];
  bottomNavigation?: SidebarItem[];
  onVisibilityChange?(): void;
}

function NavigationSection(props: NavigationSectionProps): JSX.Element {
  const { showLogo, collapsed, topNavigation, bottomNavigation } = props;
  const { onVisibilityChange } = props;

  const { t } = useTranslation();

  return (
    <>
      <div
        className={classNames(
          "hidden md:fixed md:inset-y-0 md:flex md:flex-col md:h-screen",
          collapsed ? "md:w-14" : "md:w-64"
        )}
      >
        <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white">
          <div
            className={classNames(
              "flex flex-shrink-0 items-center px-2 space-x-1",
              !showLogo ? "pt-2 pb-2" : "pt-4"
            )}
          >
            <SuiteMenu />
            {showLogo && (
              <>{!collapsed ? <Header /> : <div className="h-8" />}</>
            )}
          </div>
          <div
            className={classNames(
              "flex flex-col flex-1 overflow-y-auto",
              collapsed ? "hidden-scrollbar" : ""
            )}
          >
            <div className="flex flex-col flex-1 justify-between px-2">
              <Navigation collapsed={collapsed} itemGroups={topNavigation} />
              {bottomNavigation && (
                <Navigation
                  collapsed={collapsed}
                  itemGroups={[bottomNavigation]}
                  bottom
                />
              )}
            </div>
          </div>
          <Version short={collapsed} />
          {(!collapsed || !!onVisibilityChange) && (
            <UserProfile
              visible={!collapsed}
              toggleVisibility={onVisibilityChange}
            />
          )}
          {collapsed && !onVisibilityChange && (
            <div className="border-t border-gray-200 px-2 py-3">
              <LogoutForm>
                {(btnProps) => (
                  <button
                    {...btnProps}
                    title={t("Sign out")}
                    className={classNames(
                      "p-2 rounded-md",
                      "focus:text-gray-500 focus:bg-gray-100 focus:outline-none",
                      "text-gray-400 hover:text-gray-500 hover:bg-gray-50",
                      "flex-shrink-0"
                    )}
                  >
                    <ArrowLeftStartOnRectangleIcon className="h-6 w-6" />
                  </button>
                )}
              </LogoutForm>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function SuiteMenu(): JSX.Element {
  const { t } = useTranslation();
  return (
    <Popover className="relative">
      <PopoverButton
        className={classNames(
          "p-2 rounded-md",
          "focus:text-gray-500 focus:bg-gray-100 focus:outline-none",
          "text-gray-400 hover:text-gray-500 hover:bg-gray-50",
          "flex-shrink-0"
        )}
      >
        <Squares2X2Icon className="h-6 w-6" aria-hidden="true" />
      </PopoverButton>
      <Portal>
        <PopoverBackdrop
          transition
          className="fixed inset-0 bg-black/15 transition duration-100 ease-out data-[closed]:opacity-0"
        />
      </Portal>
      <PopoverPanel
        transition
        anchor={{ to: "left start", offset: -10 }}
        className={classNames(
          "flex flex-col bg-slate-100 rounded-md px-10 pt-4 pb-6 mx-1 border-4 border-white shadow-sm",
          "transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 origin-top",
          "space-y-4"
        )}
      >
        <p className="font-semibold text-gray-700">TIS Suite Apps</p>
        <Apps id="sideApps" className="grid grid-cols-3 gap-4" size="medium" />
        <div className="flex justify-between items-center pt-2">
          <Link
            to={getSolutionsPath()}
            className="text-xs text-gray-600 hover:text-gray-700"
          >
            {t("Change project")}
          </Link>
          <LogoutForm id="suiteMenuLogout">
            {(btnProps) => (
              <button
                {...btnProps}
                className="text-gray-600 hover:text-gray-700 underline"
              >
                {t("Sign out")}
              </button>
            )}
          </LogoutForm>
        </div>
      </PopoverPanel>
    </Popover>
  );
}

function Header(): JSX.Element {
  return (
    <Link id="headerLogoLink" to={getSolutionsPath()}>
      <div className="flex flex-shrink-0 items-center">
        <img className="h-8 w-auto" src={logo} alt="TIS Prognos AI" />
      </div>
    </Link>
  );
}

interface MobileMenuOpenButton {
  onClick(): void;
}

function MobileMenuOpenButton({ onClick }: MobileMenuOpenButton): JSX.Element {
  return (
    <div className="sticky top-0 z-10 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
      <button
        id="mobileMenuOpenButton"
        type="button"
        className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
        onClick={onClick}
      >
        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
      </button>
    </div>
  );
}

interface MobileMenuCloseButtonProps {
  onClick(): void;
}

const MobileMenuCloseButton = React.forwardRef<
  HTMLDivElement,
  MobileMenuCloseButtonProps
>((props, ref): JSX.Element => {
  const { onClick } = props;

  return (
    <div ref={ref} className="absolute top-0 right-0 -mr-12 pt-2">
      <button
        id="mobileMenuCloseButton"
        type="button"
        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
        onClick={onClick}
      >
        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
      </button>
    </div>
  );
});

interface NavigationProps {
  itemGroups: SidebarItem[][];
  collapsed?: boolean;
  bottom?: boolean;
}

function Navigation(props: NavigationProps): JSX.Element {
  const { itemGroups, collapsed, bottom } = props;

  return (
    <nav
      className={classNames("divide-y bg-white", bottom ? "border-t" : "mt-5")}
    >
      {itemGroups
        .filter((itemGroup) => itemGroup.length > 0)
        .map((itemGroup, index) => (
          <div key={index} className="space-y-1 py-1">
            {itemGroup
              .filter((item) => item.href !== "#")
              .map((item) => (
                <div
                  id={`sidebar${item.key[0].toUpperCase()}${item.key.slice(1)}`}
                  key={item.name}
                >
                  <Link
                    to={item.href}
                    className={classNames(
                      item.current
                        ? item.children && !collapsed
                          ? "bg-gray-50 text-gray-900"
                          : "bg-gray-100 text-gray-900"
                        : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                      "w-full group flex items-center px-2 py-2 font-medium rounded-md text-base md:text-sm no-underline"
                    )}
                    title={collapsed ? item.name : undefined}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-gray-500"
                          : "text-gray-400 group-hover:text-gray-500",
                        "flex-shrink-0 h-6 w-6 mr-4 md:mr-3"
                      )}
                      aria-hidden="true"
                    />
                    {!collapsed && item.name}
                  </Link>
                  {!collapsed && item.current && item.children && (
                    <div className="mt-1 space-y-1">
                      {item.children.map((subItem) => (
                        <Link
                          key={subItem.name}
                          to={subItem.href}
                          className={classNames(
                            subItem.current
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                            "group flex w-full items-center rounded-md py-2 h-10 pl-11 pr-2 text-sm font-medium no-underline"
                          )}
                        >
                          {subItem.name}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              ))}
          </div>
        ))}
    </nav>
  );
}

interface UserProfileProps {
  visible?: boolean;
  toggleVisibility?(): void;
}

function UserProfile(props: UserProfileProps): JSX.Element | null {
  const { visible = true, toggleVisibility } = props;

  const { t } = useTranslation();

  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) {
    return null;
  }

  const { email, userName, firstName, lastName } = user ?? {};

  return (
    <div
      className={classNames(
        "flex items-center border-t border-gray-200 p-4 md:p-2",
        visible ? "justify-between" : "justify-center"
      )}
    >
      {visible && (
        <div className="flex overflow-hidden">
          <div className="inline-block h-10 w-10 md:h-9 md:w-9 rounded-full bg-slate-100 text-slate-400 flex-shrink-0">
            <UserIcon className="p-1" />
          </div>
          <div className="ml-3 overflow-hidden">
            <p className="text-base md:text-sm font-medium text-gray-700 group-hover:text-gray-900 text-ellipsis overflow-hidden">
              {email ??
                (firstName && lastName ? `${firstName} ${lastName}` : null) ??
                userName}
            </p>
            <LogoutForm />
          </div>
        </div>
      )}
      {toggleVisibility && (
        <button
          id="sidebarSizeToggleButton"
          className="h-12 p-2 text-gray-400 hover:text-gray-500"
          onClick={toggleVisibility}
          title={visible ? t("Collapse sidebar") : t("Expand sidebar")}
        >
          {visible ? (
            <ChevronLeftIcon className="h-6 w-6" />
          ) : (
            <ChevronRightIcon className="h-6 w-6" />
          )}
        </button>
      )}
    </div>
  );
}
