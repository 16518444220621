import React from "react";
import { useTranslation } from "react-i18next";

import { Partition } from "../../apps/PrognosAI/models/partition";
import { Partitioner } from "../../apps/PrognosAI/models/partitioner";
import Select from "./Select";

interface HierarchicalSelectProps {
  levels: Partitioner[];
  options: Partition[];
  value: Partition | null;
  onChange(newValue: Partition | null): void;
  selectDirection?: "up" | "down";
  leafOnly?: boolean;
}

export default function HierarchicalSelect(
  props: HierarchicalSelectProps
): JSX.Element | null {
  const { levels, options, value, leafOnly = false, onChange } = props;
  const { selectDirection = "down" } = props;

  const { t } = useTranslation();

  const hierarchy = buildHierarchy(levels, options, value, t("All"), leafOnly);

  const handleChange = (value: Partition) => {
    // this is necessary because of the partition renaming below
    const option =
      options.find((o) => o.partitionId === value.partitionId) ?? value;
    onChange(option);
  };

  return (
    <>
      {hierarchy.map((level) => (
        <div key={level.key}>
          <Select
            id={`hierarchical-level-${level.key}`}
            label={level.label}
            options={level.options}
            value={level.value}
            onChange={handleChange}
            keyProp="partitionId"
            labelProp="name"
            disabled={level.options.length === 0}
            direction={selectDirection}
          />
        </div>
      ))}
    </>
  );
}

interface HierarchyLevel {
  key: string | number;
  label: string;
  options: Partition[];
  value: Partition | null;
}

const buildHierarchy = (
  levels: Partitioner[],
  options: Partition[],
  value: Partition | null,
  allLabel = "all",
  leafOnly = false
): HierarchyLevel[] => {
  const sortedLevels = [...levels].sort((a, b) => a.order - b.order);
  const levelValues = getLevelValues(sortedLevels, options, value);

  const rootPartition = options.find((p) => p.parentPartitionId === null);
  if (!rootPartition) {
    throw new Error("The root partition wasn't found in the data.");
  }
  if (
    value !== null &&
    levelValues.length > 0 &&
    levelValues[levelValues.length - 1] === null
  ) {
    levelValues[levelValues.length - 1] = rootPartition;
  }

  const result: HierarchyLevel[] = [];
  for (const level of sortedLevels) {
    const parentPartition = {
      ...(result[result.length - 1]?.value ?? rootPartition),
    };
    // renaming the parent partition so that it appears in the sublist as "all"
    parentPartition.name = parentPartition.parentPartitionId
      ? `${allLabel} (${parentPartition.name})`
      : allLabel;

    const levelOptions = options.filter(
      (o) =>
        o.partitionerId === level.partitionerId &&
        o.parentPartitionId === parentPartition?.partitionId
    );
    levelOptions.sort((a, b) => (a.name || "").localeCompare(b.name ?? ""));
    const levelValue =
      levelValues.pop() ?? (value !== null ? parentPartition : null);

    result.push({
      key: level.partitionerId,
      label: level.name,
      options: leafOnly ? levelOptions : [parentPartition, ...levelOptions],
      value: levelValue,
    });
  }

  return result;
};

const getLevelValues = (
  sortedLevels: Partitioner[],
  options: Partition[],
  value: Partition | null
): (Partition | null)[] => {
  const levelValues = [];

  let currValue: Partition | null = value;
  for (let i = sortedLevels.length - 1; i >= 0; i--) {
    if (
      currValue === null ||
      currValue.partitionerId !== sortedLevels[i].partitionerId
    ) {
      levelValues.push(null);
    } else {
      levelValues.push(currValue);
      if (currValue !== null) {
        currValue =
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          options.find((o) => o.partitionId === currValue!.parentPartitionId) ??
          null;
      }
    }
  }

  return levelValues;
};
