import React from "react";

interface ColorPickerProps {
  label: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

export default function ColorPicker({
  label,
  value,
  onChange,
}: ColorPickerProps): JSX.Element {
  return (
    <>
      <label htmlFor="type" className="block text-sm font-medium text-gray-600">
        {label}
      </label>
      <input
        type="color"
        value={value}
        onChange={onChange}
        className="block mt-1 w-full border rounded border-gray-300 bg-white cursor-pointer focus:outline-none focus:ring-primary-500 focus:border-primary-500 px-2 py-1"
        style={{ height: 38 }}
      />
    </>
  );
}
